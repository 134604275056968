import React from 'react';

function DeleteNutritionGoalModal({deleteNutritionGoal, setShowDeleteNutritionGoalModalFlag}) {

    function closeModal(){
        setShowDeleteNutritionGoalModalFlag(false);
    }

    return (
        <div id="deleteExerciseModal" className="modal" style={{display: "flex"}}>
            <div className="modal-content-centered">
                <span onClick={closeModal} className="close">&times;</span>
                <div className="modal-section">
                    <span ><h5>Are you sure you want to delete this goal?</h5></span>
                    <button className="normal-button-system" onClick={deleteNutritionGoal} style={{width: "100px", margin: "auto", marginBottom: "12px"}}>Yes</button>
                </div>
            </div>
        </div>
    );
}

export default DeleteNutritionGoalModal;